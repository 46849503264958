import React, { useState, useEffect } from "react";

import "./App.css";
import Header from "./componentes/Header";
import FormularioTareas from "./componentes/FormularioTareas";
import ListaTareas from "./componentes/ListaTareas";

const App = () => {
  //local storage
  const tareasGuardadas = localStorage.getItem("tareas")
    ? JSON.parse(localStorage.getItem("tareas"))
    : [];
  //console.log(tareasGuardadas);
  const [tareas, cambiarTareas] = useState(tareasGuardadas);
  //habilitar localstorage
  useEffect(() => {
    //console.log('Las tareas cambiaron');
    localStorage.setItem("tareas", JSON.stringify(tareas));
    //console.log(JSON.stringify(tareas));
  }, [tareas]);
  //acceso a local storage y comprobacion si el valor de mostrar completadas es null
  let configMostrarCompletadas = "";
  if (localStorage.getItem("mostrarCompletadas") === null) {
    configMostrarCompletadas = true;
  }else{
    configMostrarCompletadas = localStorage.getItem("mostrarCompletadas") === 'true';
  }
  //mostrar o no tareas completadas
  const [mostrarCompletadas, cambiarMostrarCompletadas] = useState(configMostrarCompletadas);
  //console.log(tareas);
  //habilitar localstorage
  useEffect(() => {
    localStorage.setItem("mostrarCompletadas", mostrarCompletadas.toString());
  }, [mostrarCompletadas]);

  return (
    <div className="contenedor">
      <Header
        mostrarCompletadas={mostrarCompletadas}
        cambiarMostrarCompletadas={cambiarMostrarCompletadas}
      />
      <FormularioTareas tareas={tareas} cambiarTareas={cambiarTareas} />
      <ListaTareas
        tareas={tareas}
        cambiarTareas={cambiarTareas}
        mostrarCompletadas={mostrarCompletadas}
      />
    </div>
  );
};

export default App;
