import React from "react";
import Tarea from "./Tarea";

const ListaTareas = ({ tareas, cambiarTareas, mostrarCompletadas }) => {
  //funcion para cambiar el check de las tareas del componente Tarea
  const toggleCompletada = id => {
    //console.log('Estamos editando la tarea con id', id);
    cambiarTareas(
      tareas.map(tarea => {
        if (tarea.id === id) {
          return { ...tarea, completada: !tarea.completada };
        }
        return tarea;
      })
    );
  };
  //funcion para cambiar o editar el texto de una tarea
  const editarTarea = (id, nuevoTexto) => {
    cambiarTareas(
      tareas.map(tarea => {
        if (tarea.id === id) {
          return { ...tarea, texto: nuevoTexto };
        }
        return tarea;
      })
    );
  };
  //funcion para eliminar una tarea
  const borrarTarea = id => {
    cambiarTareas(
        tareas.filter(tarea => {
          if (tarea.id !== id) {
            return tarea;
          }
          return;
        })
      );
  };
  return (
    <ul className="lista-tareas">
      {tareas.length > 0 ? (
        tareas.map(tarea => {
            if(mostrarCompletadas){
                return <Tarea
                    key={tarea.id}
                    tarea={tarea}
                    toggleCompletada={toggleCompletada}
                    editarTarea={editarTarea}
                    borrarTarea={borrarTarea}
                  />
            //devuelve tareas no completadas
            }else if(!tarea.completada){
                return <Tarea
                    key={tarea.id}
                    tarea={tarea}
                    toggleCompletada={toggleCompletada}
                    editarTarea={editarTarea}
                    borrarTarea={borrarTarea}
              />
            }
            //si la tarea ya esta completada no la devolvemos
            return;
        })
      ) : (
        <div className="lista-tareas__mensaje">No hay tareas agregadas</div>
      )}
    </ul>
  );
};

export default ListaTareas;
