import React, { useState } from "react";
//FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
//UUID
import { v4 as uuidv4 } from "uuid";

const FormularioTareas = ({ tareas, cambiarTareas }) => {
  //Cambia el estado de los inputs
  const [inputTarea, cambiarInputTarea] = useState("");
  //Funcion que capta y cambia en tiempo real los textos del input
  const handleInput = e => {
    cambiarInputTarea(e.target.value);
  };
  //Agregar nueva tarea
  const handleSubmit = e => {
    e.preventDefault();

    cambiarTareas([
      ...tareas,
      {
        id: uuidv4(),
        texto: inputTarea,
        completada: false
      }
    ]);

    cambiarInputTarea("");
  };
  return (
    <form action="" className="formulario-tareas" onSubmit={handleSubmit}>
      <input
        type="text"
        className="formulario-tareas__input"
        placeholder="Escribe una tarea"
        //Cambia los valores del input
        value={inputTarea}
        onChange={e => handleInput(e)}
      />
      <button type="submit" className="formulario-tareas__btn">
        <FontAwesomeIcon
          icon={faPlusSquare}
          className="formulario-tareas__icono-btn"
        />
      </button>
    </form>
  );
};

export default FormularioTareas;
