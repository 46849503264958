import React from 'react';
//FONT AWESOME
//npm i --save @fortawesome/fontawesome-svg-core
//npm install --save @fortawesome/free-solid-svg-icons
//npm install --save @fortawesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons';


const Header = ({mostrarCompletadas, cambiarMostrarCompletadas}) => {
    //funcion par mostrar tareas completadas
    const toggleCompletadas = () => {
        cambiarMostrarCompletadas(!mostrarCompletadas);
    }
    
    return (
        <header className="header">
            <h1 className="header__titulo">Lista de tareas</h1>
            {
                mostrarCompletadas ?
                    <button 
                        className="header__boton"
                        onClick={() => toggleCompletadas()}
                    >
                        No mostrar completadas
                        <FontAwesomeIcon icon={faEyeSlash} className="header__icono-boton" />
                    </button>
                :
                    <button 
                        className="header__boton"
                        onClick={() => toggleCompletadas()}
                    >
                        Mostrar completadas
                        <FontAwesomeIcon icon={faEye} className="header__icono-boton" />
                    </button>
            }
        </header>
    );
}
 
export default Header;

