import React, { useState } from 'react';
//FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

 const Tarea = ({tarea, toggleCompletada, editarTarea, borrarTarea}) => {
     //Estado para mostrar o no input de editar tarea
     const [ editandoTarea, cambiarEditandoTarea] = useState(false);
     //Estado para mostar la tarea en el input de editar tarea y poder editarla
     const [ nuevaTarea, cambiarNuevaTarea] = useState(tarea.texto);
     //funcion del boton submit de editar del formulario
     const handleSubmit = e => {
         e.preventDefault();
         editarTarea(tarea.id, nuevaTarea);
         cambiarEditandoTarea(false);
     }    

     return ( 
         <li className="lista-tareas__tarea">
                 <FontAwesomeIcon 
                    icon={tarea.completada ? faCheckSquare : faSquare} 
                    className="lista-tareas__icono lista-tareas__icono-check"
                    onClick={() => toggleCompletada(tarea.id)}
                />
             <div className="lista-tareas__texto">
                {editandoTarea 
                    ? 
                    <form action="" className="formulario-editar-tarea" onSubmit={handleSubmit}>
                        <input 
                            type="text" 
                            className="formulario-editar-tarea__input"
                            value={nuevaTarea}
                            onChange={(e) => cambiarNuevaTarea(e.target.value)}
                        />
                        <button 
                            type="submit"
                            className="formulario-editar-tarea__btn"
                        >
                            Actualizar
                        </button>
                    </form>
                    : tarea.texto
                }
            </div>
            <div className="lista-tareas__contenedor-botones">
                <FontAwesomeIcon 
                    icon={faEdit} 
                    className="lista-tareas__icono lista-tareas__icono-accion"
                    //accion del boton
                    onClick={() => cambiarEditandoTarea(!editandoTarea)}
                />
                <FontAwesomeIcon 
                    icon={faTimes} 
                    className="lista-tareas__icono lista-tareas__icono-accion"
                    //accion del boton
                    onClick={() => borrarTarea(tarea.id)}
                />
            </div>
         </li>
      );
 }
  
 export default Tarea;